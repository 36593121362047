.EntryPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #232323;
}

.centered-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 2rem 2rem 2rem 2rem;
}

.entrypage-footer {
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.75);
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
}

.icon-bottom {
    width: 64px;
    height: 64px;
    padding-right: 1.5rem;
    margin-bottom: 0.5rem;
    display: none;
}

.dummy-footer-entry {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}

.login-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff3b;
    margin-top: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding: 5rem 10rem 5rem 10rem;
    font-size: 1.2rem;
    border-radius: 6px;
}

.login-text-header-img {
    margin-bottom: 2rem;
}

.login-text-button {
    margin-top: 2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 6px;
    background-color: #ffffff3b;
    color: #ffffff;
    padding-top: 8px;
    padding-bottom: 4px;
    font-size: 1rem;
    font-weight: 200;
    font-family: Chicago;
    border: none;
    cursor: pointer;
}

.login-text-button:hover {
    background-color: #ffffff5b;
}

.login-text-body {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 2rem;
    display: none;
    font-family: Chicago;
    color: #ffffff;
}