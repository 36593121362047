@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display&display=swap');

.ServicesPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #232323;
    height: 100vh;
    width: 100%;
    font-family: "Chicago";
}

.ServicesPage-header {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 3rem;
    margin-right: 3rem;
    width: 100%;
}

.ServicesPage-header-text {
    font-size: 1.2rem;
    font-weight: 700;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
    color: black;
    background-color: white;
}

.ServicesPage-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-left: 3rem;
    margin-right: 3rem;
    color: white;
}

.ServicesPage-body-header {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 0;
    padding-top: 0.8rem;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
}

.ServicesPage-body-text {
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0;
    padding-left: 4px;
    padding-right: 4px;
    color: rgb(255, 255, 255, 0.75);
}

.ServicesPage-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    font-family: "Yatra One";
    color: rgb(255, 255, 255, 0.75);
    width: 100%;
}

.ServicesPage-footer-text {
    font-weight: 400;
    margin-top: 0;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 0.3rem;
    color: rgb(255, 255, 255, 0.75);
}

.ServicesPage-footer-text-bottom {
    font-weight: 400;
    margin-top: 0;
    padding-left: 4px;
    padding-right: 4px;
    color: rgba(255, 255, 255, 0.517);
}