.RedirectPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #232323;
    font-family: Chicago, monospace;
    text-align: center;
}

.centered-redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.RedirectPage-title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background-color: #FFF;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.RedirectPage-subtitle {
    font-size: 0.8rem;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 0;
    color: #fff;
}