.ErrorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #232323;
    font-family: "Chicago", monospace;
    text-align: center;
}

.centered-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    flex-grow: 1;
    margin-left: 3rem;
    margin-right: 3rem;
}

.ErrorPage-title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 3rem;
    background-color: #FFF;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.ErrorPage-subtitle {
    font-size: 0.8rem;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 0;
    color: #fff;
}

.ErrorPage-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    margin-bottom: 3rem;
    color: rgba(255, 255, 255, 0.75);
    margin-left: 3rem;
    margin-right: 3rem;
}

.ErrorPage-footer-text {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.email-link-error {
    color: #61dafb;
    text-decoration: none;
}