.link {
    color: #61dafb;
    text-decoration: none;
    padding: 4px 8px 4px 8px;
}

.link:hover {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 4px;
    padding-top: 8px;
    transition: background-color 0.2s ease-in-out;
}

.link-bg {
    margin: 4px 4px 4px 4px;
}