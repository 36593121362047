@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Inter:wght@100..900&family=Playfair+Display&family=Yatra+One&display=swap');

@font-face {
  font-family: 'Chicago';
  src: local('Chicago'), url(assets/fonts/chicago.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Mono", 'SF Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
