.App {
  display: flex;
  flex-direction: row;
  background-color: #232323;
  height: 100vh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  color: white;
  font-family: Chicago;
  width: 50%;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  margin-right: 3rem;
  height: 100vh;
  width: 50%;
  font-family: Chicago;
}

.user-name {
  font-size: 3.5rem;
  font-weight: 800;
  text-align: right;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
  color: rgb(125, 255, 118);
}

.user-subtitle {
  font-size: 1.2rem;
  margin-left: 3rem;
  margin-bottom: 2rem;
  font-weight: 400;
  text-align: right;
  margin-top: 0;
  padding-top: 0.8rem;
  padding-left: 4px;
  padding-right: 4px;
  color: rgba(255, 255, 255, 0.75);
}

.ek-shlok {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  margin-left: 3rem;
  margin-top: 2.5rem;
  font-family: "Yatra One";
}

.footer-in-first {
  display: flex;
  justify-content: start;
  flex-direction: row;
  margin-bottom: 2.5rem;
  margin-left: 3.5rem;
  font-family: "Yatra One";
}

.only-on-mobile {
  display: none;
  align-self: flex-end;
  justify-content: end;
  flex-direction: row;
  margin-top: 2rem;
  flex-grow: 1;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.50);
  font-family: "Yatra One";
}

.header-data {
  margin-left: 3.5rem;
  margin-bottom: 0.75rem;
  font-size: 4rem;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.10);
}

.footer-text {
  color: rgba(255, 255, 255, 0.50);
}

.user-links {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex-wrap: wrap;
  align-items: end;
}

.img-happy-mac {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1026px) {
  .footer-in-first {
    display: none;
  }

  .header-data {
    display: none;
  }

  .App-header {
    display: none;
  }

  .user-info {
    width: 100%;
  }

  .only-on-mobile {
    display: flex;
  }

  .user-links {
    display: none;
  }
}